import Vue from 'vue';
import axios from 'axios';
import { isObject, isString } from '@/common/scripts/validator';

class Instance {
    currentEnv = 'production';
    baseURL = {};
    apiExtraConfig = {};
    service = {}; // axios实例
    constructor(config) {
        /**
         * config 结构
         * {
         *     currentEnv:String 当前环境（development/test/internalTest/production）
         *     baseUrls:Object 基础路径组
         *     apiExtraConfig:Object 额外配置
         * }
         **/

        // 验证配置格式
        if (!(isObject(config) && isObject(config.baseUrls) && isString(config.currentEnv))) {
            console.error('request初始化必须传入appConfig');
            return;
        }

        // 读取配置项
        this.currentEnv = config.currentEnv || 'production'; // 当前的运行环境，默认生产环境
        this.baseURL = config.baseUrls;

        // create an axios instance
        this.service = axios.create({
            baseURL: this.baseURL.API_URL, // url = base url + request url
            // withCredentials: true, // send cookies when cross-domain requests
            timeout: 60000, // request timeout
        });
    }

    get(_api, data, extraConfig) {
        return this._request(_api, {
            method: 'GET',
            data: data || {},
            extraConfig: extraConfig || {},
        });
    }

    post(_api, data, extraConfig) {
        return this._request(_api, {
            method: 'POST',
            data: data || {},
            extraConfig: extraConfig || {},
        });
    }

    put(_api, data, extraConfig) {
        return this._request(_api, {
            method: 'PUT',
            data: data || {},
            extraConfig: extraConfig || {},
        });
    }

    delete(_api, data, extraConfig) {
        return this._request(_api, {
            method: 'DELETE',
            data: data || {},
            extraConfig: extraConfig || {},
        });
    }

    patch(_api, data, extraConfig) {
        return this._request(_api, {
            method: 'PATCH',
            data: data || {},
            extraConfig: extraConfig || {},
        });
    }

    // 请求方法
    async _request(_api, _config) {
        try {
            // 基础请求配置
            const _baseConfig = {
                url: this.baseURL.API_URL,
                headers: {},
            };

            // 额外配置必须是Object类型，如果传入的是其它类型，则需要把额外配置设置为空对象
            if (!isObject(_config.extraConfig)) {
                _config.extraConfig = {};
            }

            // 保存_config中的额外配置之后，为了避免影响请求，需要将额外配置从_config中删除
            const _extraConfig = JSON.parse(JSON.stringify(_config.extraConfig));
            delete _config.extraConfig;

            Object.assign(_baseConfig, _config);

            // 获取额外配置中的请求类型
            if (isString(_extraConfig.responseType)) {
                _baseConfig.responseType = _extraConfig.responseType;
            }

            // 拼接请求地址
            _baseConfig.url = _baseConfig.url + _api;

            // 拼接请求方式
            _baseConfig.method = (_config.method || 'GET').toUpperCase();

            // 拼接请求的参数
            // `data` 是作为请求体被发送的数据
            // 仅适用 'PUT', 'POST', 'DELETE 和 'PATCH' 请求方法

            // `params` 是与请求一起发送的 URL 参数
            // 必须是一个简单对象或 URLSearchParams 对象
            const _methodUseDataKey = ['PUT', 'POST', 'DELETE', 'PATCH'];
            if (_methodUseDataKey.indexOf(_baseConfig.method) < 0) {
                _baseConfig.params = _config.data;
            } else {
                _baseConfig.data = _config.data;
            }

            // 拼接Token
            // if (store.getters.token) {
            //     _baseConfig.headers['Authorization'] = getToken();
            // }

            return new Promise((resolve, reject) => {
                this.service
                    .request(_baseConfig)
                    .then((res) => {
                        _responseInterceptor(res);
                    })
                    .catch((err) => {
                        if (err.response) {
                            _responseInterceptor(err.response);
                        } else {
                            reject(err);
                        }
                    });

                // 请求拦截器
                let _userServiceOfflineTimeout;
                const _successedHTTPStatusCode = [200]; // 表明是正确的HTTP状态码
                const _successedStatusCode = [200]; // 表明是正确的接口状态码
                // const _erroredStatusCode = [401, 503]; // 表明是错误的接口状态码

                // 响应拦截器
                const _responseInterceptor = (res) => {
                    res.data = res.data || {};

                    const _httpCode = Number(res.status); // http状态码
                    const _resCode = Number(res.data.code); // 接口状态码

                    // 首先判断HTTP状态码是否正确
                    if (_successedHTTPStatusCode.indexOf(_httpCode) < 0) {
                        return reject(res.data);
                    }

                    // 判断是否跳过了响应拦截，如果跳过了，则直接返回
                    if (_extraConfig.interceptor === false) {
                        return resolve(res.data);
                    }

                    // 判断接口状态码
                    if (_successedStatusCode.indexOf(_resCode) >= 0) {
                        return resolve(res.data.data);
                    } else {
                        if (_resCode === 40400) {
                            Errored('数据不存在');
                        } else if (_resCode === 40000) {
                            Errored('表单参数错误');
                        } else if (_resCode === 50000) {
                            Errored('服务器错误');
                        } else {
                            Errored('未知错误');
                        }

                        return reject(res.data);
                    }
                };
            });
        } catch (err) {
            return Promise.reject(err);
        }
    }
}

// 错误提示
function Errored(_msg) {
    alert(_msg);
}

export default Instance;