const getImageUrl = (name) => {
    // return new URL(`../../assets/images/${name}`,
    //     import.meta.url).href

    return require(`../../assets/images/${name}`);
};

const isWeixin = () => {
    return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
}

export {
    getImageUrl,
    isWeixin
}