import './style.less'

import router from './router/index'
import { createApp } from 'vue'
import App from './App.vue'

import '@/common/scripts/flexible.js';

const app = createApp(App)
app.use(router)
app.mount('#app')