<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <router-view />
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import { isMobile, getUrlRelativePath } from '@/api/index';

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  beforeCreate(){
	if(!isMobile()){
		let path = getUrlRelativePath(location.href.toString());
		location.href = 'https://www.denouementatelier.com' + path;
	}
  }
}
</script>

<style></style>
