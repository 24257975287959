/*========================
=    表验证器及其方法     =
========================*/

/**
 * _regExp:Object 正则对象合集
 * ValidateError:Function 验证错误对象
 * _IsValidParam:Function 验证校验方法中被传入的参数是否正确
 * isEmpty:Function 验证是否为空
 * isObject:Function 验证是否是对象
 * isArray:Function 验证是否是数组
 * isEmail:Function 验证是否是电子邮件地址
 * isNumber:Function 验证是否是数字类型
 * isIntNumber:Function 验证是否是正整数
 * isString:Function 验证是否是字符串(含空字符串)
 * isPhoneNumber:Function 验证是否是手机号码
 * isIDNumber:Function 验证是否是正确的身份证号码
 * isExternal:Function 验证是否是外部链接
 */
/**
 * 性别过滤器
 */
export const gender = [
    {
        label: '未设置',
        value: 0,
    },
    {
        label: '男',
        value: 1,
    },
    {
        label: '女',
        value: 2,
    }
];
export function getGender(value) {
    let val = value;
    if (typeof value === 'string') val = parseInt(value);
    const genderItem = _.find(gender, {
        value: val
    });
    if (genderItem) return genderItem.label;
}
// 2022-01-18 发现使用reg.test时，正则表达式内不能带上参数g和m
const _regExp = {
    email: /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/i,
    url: /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/i,
    empty: /^\s*$/i,
    phoneNumber: /^1[3456789]\d{9}$/i,
    IDNumber:
        /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X|x])$/i,
    intNumber: /^\+?[1-9][0-9]*$/i,
    postcode: /^[0-9]{6}$/i,
    passportNo: /^([EK]\d{8}|(SE|DE|PE|MA)\d{7})$/, // * 护照正则
    HMCardValid: /^[H|h|M|m](\d{8}|\d{10})$/, // * 港澳居民来往内地通行证
    TWCardValid: /^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/, // * 台湾居民来往内地通行证
    externalLink: /^(https?:|mailto:|tel:)/, // 外部链接
    IDcradDesensitization: /^(.{4})(?:\w+)(.{4})$/, // 身份证脱敏
    phoneDesensitization: /(\d{3})\d*(\d{4})/ // 手机号码脱敏
};

const ValidateError = function (message, value) {
    this.value = value;
    this.message = message;
    this.toString = function () {
        return this.value + this.message;
    };
};

// 除了isObject和IsArray，其他的校验器都要求是String输入
const _IsValidParam = (_param) => {
    if (typeof _param !== 'string') {
        throw new ValidateError('参数只支持String类型', _param);
    }
    //  else if (_regExp.empty.test(_param)) {
    //     throw new ValidateError('请传入需要校验的参数', _param);
    // }
};

export const REGRULES = _regExp;

// 判断是不是外部链接
export function isExternal(path) {
    return _regExp.externalLink.test(path);
}

// 判断是不是为空
export function isEmpty(_param) {
    try {
        _IsValidParam(_param);

        return _regExp.empty.test(_param);
    } catch (err) {
        console.error(err);
        return false;
    }
}

// 判断是不是对象
export function isObject(_object) {
    return Object.prototype.toString.apply(_object) === '[object Object]';
}

// 判断是不是数组
export function isArray(_array) {
    return Object.prototype.toString.apply(_array) === '[object Array]';
}

// 判断是不是Email
export function isEmail(_email) {
    try {
        _IsValidParam(_email);
        return _regExp.email.test(_email);
    } catch (err) {
        console.error(err);
        return false;
    }
}

// 判断是不是数字
export function isNumber(_number) {
    try {
        _IsValidParam(_number);

        // 检查是不是为空
        if (_number === '') {
            return false;
        }

        // 检查是不是NaN
        _number = Number(_number);
        if (String(_number) === 'NaN') {
            return false;
        }

        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
}

// 判断是不是正整数
export function isIntNumber(_number) {
    try {
        _IsValidParam(_number);

        // 检查是不是数字
        if (!isNumber(_number)) {
            return false;
        }

        return _regExp.intNumber.test(_number);
    } catch (err) {
        console.error(err);
        return false;
    }
}

// 判断是不是String
export function isString(_string) {
    return typeof _string === 'string';
}

// 判断是不是URL
export function isURL(_url) {
    if (typeof _url !== 'string') {
        return false;
    }

    return _regExp.url.test(_url);
}

// 判断是不是Boolean
export function isBoolean(_boolean) {
    return typeof _boolean === 'boolean';
}

// 判断是不是手机号码
export function isPhoneNumber(_phoneNumber) {
    try {
        _IsValidParam(_phoneNumber);
        return _regExp.phoneNumber.test(_phoneNumber);
    } catch (err) {
        console.error(err);
        return false;
    }
}

// 判断是不是护照编号   普通护照、公务护照、澳门特别行政区护照和香港特别行政区护照四种类型
// 普通护照：E+8 位数字编号
// 公务护照：SE+7 位数字编码 外交护照：DE+7 位数字编码  公务普通护照：PE+7 位数字编码
// 澳门特别行政区护照：MA+7 位编号
// 香港特别行政区护照：K+8 位编号
export function isPassportNo(_passportNo) {
    try {
        _IsValidParam(_passportNo);
        return _regExp.passportNo.test(_passportNo);
    } catch (err) {
        console.error(err);
        return false;
    }
}
// 判断是不是港澳居民来往内地通行证
// 规则：通行证证件号码共11位。第1位为字母，“H”字头签发给香港居民，“M”字头签发给澳门居民；
// 第2位至第11位为数字，前8位数字为通行证持有人的终身号，后2位数字表示换证次数，首次发证为00，此后依次递增。
// H/M + 8位或10位数字
// 样本： H12345678 或 H1234567801
export function isHMCardValid(_HMCardValid) {
    try {
        _IsValidParam(_HMCardValid);
        return _regExp.HMCardValid.test(_HMCardValid);
    } catch (err) {
        console.error(err);
        return false;
    }
}

// 判断是不是台湾居民来往大陆通行证
// 规则： 新版8位或18位数字， 旧版10位数字 + 英文字母
//  样本： 12345678 或 1234567890B  R122159007
export function isTWCardValid(_TWCardValid) {
    try {
        _IsValidParam(_TWCardValid);
        if (_TWCardValid.length > 20) {
            return false;
        }
        return _regExp.TWCardValid.test(_TWCardValid);
    } catch (err) {
        console.error(err);
        return false;
    }
}

// 判断是不是身份证号码
/*
 *
 * @params {string} _IDNumber
 *
 * 函数参数必须是字符串，因为二代身份证号码是十八位，而在javascript中，十八位的数值会超出计算范围，造成不精确的结果，
 * 导致最后两位和计算的值不一致，从而该函数出现错误（详情查看javascript的数值范围）。
 * 为了避免这一误差，_IDNumber必须是字符串
 *
 * 正则思路：
 *   第一位不可能是0
 *   第二位到第六位可以是0-9
 *   第七位到第十位是年份，所以七八位为19或者20
 *   十一位和十二位是月份，这两位是01-12之间的数值
 *   十三位和十四位是日期，是从01-31之间的数值
 *   十五，十六，十七都是数字0-9
 *   十八位可能是数字0-9，也可能是X
 * */
export function isIDNumber(_IDNumber) {
    try {
        _IsValidParam(_IDNumber);

        // 判断格式是否正确
        const format = _regExp.IDNumber.test(_IDNumber);
        if (!format) {
            return false;
        }
        // 加权因子
        const weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        // 校验码
        const check_code = ['1', '0', 'x', '9', '8', '7', '6', '5', '4', '3', '2'];
        const last = _IDNumber[17].toLocaleLowerCase(); //最后一位
        const seventeen = _IDNumber.substring(0, 17);

        // ISO 7064:1983.MOD 11-2
        // 判断最后一位校验码是否正确
        const arr = seventeen.split('');
        const len = arr.length;
        let num = 0;
        for (let i = 0; i < len; i++) {
            num += arr[i] * weight_factor[i];
        }
        // 获取余数
        const resisue = num % 11;
        const last_no = check_code[resisue];
        // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
        const result = last === last_no;

        return result;
    } catch (err) {
        console.error(err);
        return false;
    }
}

// 判断是不是邮政编码
export function isPostcode(_postcode) {
    try {
        _IsValidParam(_postcode);
        return _regExp.postcode.test(_postcode);
    } catch (err) {
        console.error(err);
        return false;
    }
}

// 表单校验器
export function validator(_data, _rules) {
    try {
        const _rulesKeys = Object.keys(_rules);
        const _rulesValues = Object.values(_rules);
        const _result = {};

        /*=========================
         =      处理字段规则      =
        =========================*/
        for (let _index = 0; _index < _rulesValues.length; _index++) {
            const _item = _rulesValues[_index];
            const _itemKey = _rulesKeys[_index];

            // 先判断这个字段规则是对象还是数组
            if (isArray(_item)) {
                for (var _idx = 0; _idx < _item.length; _idx++) {
                    const _it = _item[_idx];

                    // 校验字段值和规则是否匹配
                    const _itemResult = contrastRuleAndData(_it, _data[_itemKey]);
                    if (_itemResult) {
                        if (!isArray(_result[_itemKey])) {
                            _result[_itemKey] = [];
                        }

                        _result[_itemKey] = _result[_itemKey].concat(_itemResult);
                    }
                }
            } else {
                const _itemResult = contrastRuleAndData(_item, _data[_itemKey]);
                if (_itemResult) {
                    _result[_itemKey] = _itemResult;
                }
            }
        }

        return _result;
        /*=====  End of 处理字段规则  ======*/
    } catch (err) {
        console.error(err);
        return false;
    }
}

// 对比给定的规则和数据是否相匹配
/**
 * @_rule {Object} 规则体
 * @_item {Object} 数据对象
 *
 * 对比函数必须传入两个参数
 *
 * 对比思路
 * 1.先判断是不是必填项，必填项需要校验填没填
 * 2.判断有没有自定义校验器，如果有则直接走自定义校验器，没有则进入下一判断
 * 3.校验字符长度限制
 * 4.校验数字大小限制
 * 5.更多功能待添加
 */
const contrastRuleAndData = (_rule, _item) => {
    _item = _item || '';

    const _itemCheckMsg = _rule.message || '校验未通过';

    const _result = {};

    // 检查是不是必填
    if (_rule.required === true) {
        if (typeof _item === 'undefined') {
            _result.required = {
                valid: false,
                message: _itemCheckMsg,
            };
        } else if (_regExp.empty.test(_item)) {
            _result.required = {
                valid: false,
                message: _itemCheckMsg,
            };
        }
    } else if (_item === '') {
        // 如果不是必填且没有填，则直接返回
        return;
    }

    // 检查是不是有自定义验证器【最高优先级】
    if (typeof _rule.validator !== 'undefined') {
        if (typeof _rule.validator !== 'function') {
            throw new ValidateError('validator参数必须为Function', _rule.validator);
        }

        if (!_rule.validator(_item)) {
            _result.validator = {
                valid: false,
                message: _itemCheckMsg,
            };
        }
    }

    // 检查数据类型
    if (typeof _rule.type === 'undefined') {
        _rule.type = 'String';
    }
    if (_rule.type === 'String') {
        if (!isString(_item)) {
            _result.type = {
                valid: false,
                message: _itemCheckMsg,
            };
        }
    } else if (_rule.type === 'Number') {
        if (!isNumber(_item)) {
            _result.type = {
                valid: false,
                message: _itemCheckMsg,
            };
        }
    } else if (_rule.type === 'Boolean') {
        if (!isBoolean(_item)) {
            _result.type = {
                valid: false,
                message: _itemCheckMsg,
            };
        }
    }

    // 检查最大长度
    if (typeof _rule.maxLength !== 'undefined') {
        if (_item.length > _rule.maxLength) {
            _result.maxLength = {
                valid: false,
                message: _itemCheckMsg,
            };
        }
    }

    // 检查最小长度
    if (typeof _rule.minLength !== 'undefined') {
        if (_item.length < _rule.minLength) {
            _result.minLength = {
                valid: false,
                message: _itemCheckMsg,
            };
        }
    }

    // 只有type为Number时生效
    if (_rule.type === 'Number') {
        // 检查数值最大
        if (typeof _rule.max !== 'undefined' && isNumber(String(_rule.max)) && isNumber(_item)) {
            if (Number(_item) > _rule.max) {
                _result.max = {
                    valid: false,
                    message: _itemCheckMsg,
                };
            }
        }

        // 检查数值最小
        if (typeof _rule.min !== 'undefined' && isNumber(String(_rule.min)) && isNumber(_item)) {
            if (Number(_item) < _rule.min) {
                _result.min = {
                    valid: false,
                    message: _itemCheckMsg,
                };
            }
        }
    }

    // 结果集
    const _resultObj = [];
    const _resultKeys = Object.keys(_result);
    const _resultValues = Object.values(_result);

    for (let _resultIndex = 0; _resultIndex < _resultValues.length; _resultIndex++) {
        const _resultItem = _resultValues[_resultIndex];
        const _resultItemKey = _resultKeys[_resultIndex];

        const _itemTmp = {
            ruleKey: _resultItemKey,
            ..._resultItem,
        };

        _resultObj.push(_itemTmp);
    }

    if (_resultObj.length === 0) {
        return;
    }

    return _resultObj;
};

// const parseRulesArr = (_rulesArr) => {
//     if (!isArray(_rulesArr)) {
//         throw ValidateError('parseRulesArr仅支持传入Array参数', _rulesArr)
//         return
//     }

//     const _rules = {}
//     for (var _index = 0; _index < _rulesArr.length; _index++) {
//         const _item = _rulesArr[_index]

//         Object.assign(_rules, _item)
//     }

//     return _rules
// }
